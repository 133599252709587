<template>
  <div class="energyMall-box">
    <div class="box">
      <div class="top">
        <div class="title">
          <div class="left">
            <span>能量说明</span>
            <van-icon name="question" size="12" />
          </div>
          <div class="center">今日新增能量</div>
          <div class="right" @click="toShop">去使用</div>
        </div>
        <div class="num">50</div>
      </div>
      <div class="bottom">
        <van-pull-refresh
          v-model="isLoading"
          success-text="刷新成功"
          style="min-height: 100vh"
          @refresh="onRefresh"
        >
          <div class="item">
            <div class="left">
              <div class="title">注册赠送能量</div>
              <div class="time">2021-12-10 10:50:01</div>
            </div>
            <div class="right">+50</div>
          </div>
        </van-pull-refresh>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { PullRefresh, Icon } from 'vant'

Vue.use(PullRefresh).use(Icon)
export default {
  data() {
    return {
      isLoading: false
    }
  },
  mounted() {},
  methods: {
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false
      }, 2000)
    },
    toShop() {
      this.$router.go(-1)
    }
  }
}
</script>
<style scoped>
#app {
  background: #f8f8f8 !important;
}
.box {
  background: #FA3742;
  width: 100%;
  height: 130px;
  position: relative;
  z-index: 0;
}
.box .top {
  text-align: center;
  color: #fff;
  z-index: 1;
  /* padding: 15px; */
}
.box .top .title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
  font-size: 12px;
}
.box .top .title .left{
  display: flex;
  align-items: center;
  padding-left: 15px;
}
.box .top .title .right {
  text-align: center;
  background: #fff;
  color: #FA3742;
  border-radius: 10px 0 0 10px;
  width: 46px;
  line-height: 20px;
  font-size: 10px;
}
.box .top .num {
  font-size: 25px;
  padding-top: 15px;
  font-weight: 600;
}
.box .bottom {
  background: #f8f8f8;
  border-radius: 10px;
  padding: 0 10px 10px;
  margin: 20px 15px;
}
.box .bottom .item {
  background: #fff;
  padding: 10px 15px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: #333;
  margin-bottom: 10px;
}

.box .bottom .item .left .time {
  color: #999999;
  padding-top: 10px;
  font-size: 12px;
}

.box .bottom .item .right {
  color: #FA3742;
}
.van-icon {
  margin-top: -4px;
  padding-left: 5px;
}
</style>
